.footer {
  display: flex;
  color: white;
  justify-content: space-between;
  gap: 1.5rem;
  align-items: center;
  padding: 0.5rem 2rem;
  min-height: 50px;
  background-color: rgb(90, 143, 141);
}

.socials {
  display: flex;
  gap: 1rem;
  padding: 0;
}

.footer a {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 645px) {
  .footer {
    flex-direction: column-reverse;
    justify-content: center;
    gap: 0;
  }
}
