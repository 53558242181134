.navbar {
  z-index: 100;
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-between;
  padding-inline: 2rem;
  position: sticky;
  top: 0;
  background-color: rgb(89, 144, 142, 0.95);
  transition: all ease-in-out 0.2s;
}

.nav-bar-top {
  transition: all ease-in-out 0.5s;
  background-color: transparent;
}

.nav-bar__portfolio {
  transition: none;
}

.nav-list {
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav-list a {
  text-decoration: none;
  color: inherit;
}
