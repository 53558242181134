.overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgb(89, 144, 142);
  opacity: 0.5;
}
