.hero-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
}

.typewriter {
  color: rgb(76, 118, 117);
}
