.logos-container {
  display: flex;
  background-color: rgba(220, 255, 255, 0.9);
  justify-content: space-around;
  border-radius: 12px;
  padding: 1rem;
  gap: 0.5rem;
  flex-wrap: wrap;
  max-width: 400px;
  margin-inline: auto;
}

.logos {
  transform: translateX(0);
  transition: all 0.7s ease-in-out;
  opacity: 1;
  position: relative;
}

.hidden {
  transform: translateX(-50px);
  opacity: 0;
}

.logos-overlay {
  position: absolute;
  top: 48%;
  left: 50%;
  width: 60px;
  height: 60px;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0);
  opacity: 0;
  border-radius: 12px;
  transition: 0.3s all ease-in-out;
}

.logos-overlay p {
  color: white;
  font-size: 10px;
  margin: 0;
  user-select: none;
}

.logos-overlay:hover {
  opacity: 0.8;
}
