.contact-container {
  background-color: rgb(224, 255, 254);
  padding: 0.5rem 0 1.5rem 0;
  color: rgb(90, 143, 141);
}

.contact-header {
  text-align: center;
  margin-bottom: 2rem;
}

.contact-header h3 {
  font-size: 2rem;
  text-decoration: underline;
  margin-bottom: 0;
}

.contact-header h4 {
  margin: 1rem 0 3rem;
}

.content {
  display: flex;
  gap: 2rem;
}

.content img {
  border-radius: 12px;
  width: 310px;
  transform: translateY(20px);
}

@media screen and (max-width: 645px) {
  .content {
    gap: 0;
  }

  .content img {
    display: none;
  }
}
