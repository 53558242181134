.drawer {
  position: fixed;
  top: 0;
  width: 60%;
  height: 100%;
  z-index: 100;
  background-color: rgba(92, 145, 144);
  color: white;
  text-align: center;
}

.drawer h1 {
  font-size: 1.5rem;
  padding: 0 0.5rem 0.5rem;
  text-shadow: 2px 2px 2px black;
}

.drawer ul {
  margin-block: 1rem;
  padding: 0;
}

.drawer ul li {
  max-width: 100%;
  margin: auto 2rem;
  padding: 1.5rem 0;
  border-bottom: white 1px solid;
  transition: 0.5s all ease-in-out;
}

.drawer ul li:hover {
  text-shadow: 2px 2px 2px black;
}

.drawer.closed {
  transition: transform ease-in-out 0.5s;
  transform: translateX(-100%);
}

.drawer.open {
  transition: transform ease-in-out 0.5s;
  transform: translateX(0);
}
