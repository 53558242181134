@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
#root {
  font-family: 'Roboto Slab', sans-serif;
  margin: 0;
}

.flex {
  display: flex;
}

.flex.col {
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover,
button:hover {
  cursor: pointer;
}

ul {
  list-style: none;
}
